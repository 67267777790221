import React, { useEffect, useState } from "react";
import "../styles/Product.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ProductPage = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    ProductList();
  }, []);

  const ProductList = () => {
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/list/products",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        setProducts(data.data.list);
        console.log(data.data.list);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const addToCheckOut = (product) => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const existingItem = cartItems.find((item) => item.p_id === product.p_id);
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      cartItems.push({ ...product, quantity: 1 });
    }
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    navigate("/CheckOut");
  };

  const addToCart = (product) => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const existingItem = cartItems.find((item) => item.p_id === product.p_id);
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      cartItems.push({ ...product, quantity: 1 });
    }
    localStorage.setItem("cartItems", JSON.stringify(cartItems));

    navigate("/cart");
  };

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const totalPages = Math.ceil(products?.length / productsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const baseUrl = "https://lunarsenterprises.com:6013/";

  return (
    <>
      <div className="Product-name">
        <div />
        <h2>Products</h2>
        <div />
      </div>
      <div className="product-list">
        {currentProducts?.map((product, index) => (
          <div className="product-card" key={index}>
            <div className="image-container">
              <img
                src={baseUrl + product.p_image}
                alt={product.p_name}
                className="product-image"
              />
            </div>
            <div className="product-info">
              <h3 className="product-title">{product.p_name}</h3>
              <p className="product-price">₹{product.p_price}</p>

              <div className="product-rating">
                <span>Stock Available: {product.p_stocks}</span>
              </div>
              <div className="custom-buttons-container">
                <button
                  className="custom-button custom-add-to-cart-button"
                  onClick={() => addToCart(product)}
                >
                  Add to Cart
                </button>
                <button
                  className="custom-button custom-buy-now-button"
                  onClick={() => addToCheckOut(product)}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default ProductPage;
